import React from "react";
import { withTranslation } from 'react-i18next';
import { CustomerContext } from "../../../common/CustomerContext.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InviteProducerPopup from './InviteProducerPopup';
import { mapRegion} from '../../../common/helpers/RegionHelper'
import { mapLand} from '../../../common/helpers/RegionHelper'


class KnownProducers extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            winemakerList: [],
            wineId: '',
            winmakerName: '',
            winmakerAddress: '',
            rowClick:false
        };

        this.openOnRowClick = this.openOnRowClick.bind(this);
        this.closeOnClick = this.closeOnClick.bind(this);
    }

    componentDidMount() {
        this.getproducerList();
    }

    closeOnClick() {
        console.log("Close button on click");
        this.setState({
            rowClick: !this.state.rowClick
        });
    }

    openOnRowClick(event,wineid,winmakername, winmakeraddress) {
        event.preventDefault();
        this.setState({
            wineId: wineid,
            winmakerName: winmakername,
            winmakerAddress: winmakeraddress,
            rowClick: !this.state.rowClick
            });
    }

    getproducerList() {
        this.context.apiService.getProducerList()
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({
                        winemakerList: res,
                        });
                });
                } else {
                        console.log(res);
                        console.log(res.errors)
                }
            });
    }

    render() {
        const { t } = this.props;

        return <div>
            <Accordion elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Typography><b>{t('euvinoModal.producerInformation')}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <br />
                    <div style={{ paddingTop: '1em', cursor: 'pointer'}}>
                {this.props.updateKnownProducersFlag?this.getproducerList():null}
                <Paper elevation={2}>
                    <Table size="small" aria-label="a   dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><b>WIN-ID</b></TableCell>
                                <TableCell align="center"><b>{t('euvinoModal.producerName')}</b></TableCell>
                                <TableCell align="left"><b>{t('euvinoModal.emailAddressProducer')}</b></TableCell>
                                <TableCell align="center"><b>{t('euvinoModal.region')}</b></TableCell>
                                <TableCell align="center"><b>{t('euvinoModal.country')}</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.winemakerList.map(item => (
                            <TableRow hover key={item.winid} onClick={(event)=>this.openOnRowClick(event,item.winid,item.name,item.email) }>
                                <TableCell align="center">{item.winid}</TableCell>
                                <TableCell align="left">{item.name }</TableCell>
                                <TableCell align="left">{item.email }</TableCell>
                                <TableCell align="center">{mapRegion(item.region)}</TableCell>
                                <TableCell align="center">{mapLand(item.region)}</TableCell>
                            </TableRow>
                        ))}
                        {this.state.rowClick? <InviteProducerPopup rowClickValue={this.state.wineId} closePopup={this.closeOnClick} winId={this.state.wineId} winmakerName={this.state.winmakerName} winmakerAddress={this.state.winmakerAddress}/>:null}
                        </TableBody>

                    </Table>
                </Paper>
            </div>
                </AccordionDetails>
            </Accordion>
        </div>;
    }

}
export default withTranslation()(KnownProducers);
