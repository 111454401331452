
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from "react";
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import { red } from '@material-ui/core/colors';
import { saveAs } from "file-saver";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getCookie } from '../../common/helpers/Common';
import {palette} from '../../assets/Palette';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const emailCountries = ['BG','CZ','DE','HR','PL','RO/MD','SK']

const countryEmailIds = {
    'BG': 'pim.support@bg.markant.com',
    'CZ': 'pim.support@cz.markant.com',
    'DE': 'promis.support@de.markant.com',
    'HR': 'pim.support@hr.markant.com',
    'PL': 'pim.support@pl.markant.com',
    'RO/MD': 'pim.support@ro.markant.com',
    'SK': 'pim.support@sk.markant.com'
}


class DataRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: emailCountries[2],
            inputValue: '',
            email: countryEmailIds[emailCountries[2]],
            requesterEmail: getCookie('user_mail').replace(/"/g, ''),
            itemDetails: this.props.selectedItems,
            countryEkgCodeList: this.props.ekgCodeList['DE'],
            tosterOpen: false,
            emailSuccessToster: false,
            emailErrorToster: false,
            emailIconDisable: false,
            emailIconColor: 'primary',
        }

        this.exportMarkantData = this.exportMarkantData.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selectedItems } = nextProps;
        if(selectedItems !== this.state.itemDetails) {
            this.setState({itemDetails: selectedItems});
        }
    }

    renderEmailData(t) {
        let gpcClassification = []
        if (this.props.toggleDataRequestModal) {
            gpcClassification.push(<h2 key={'h1'}>Markant {t('markantDataRequestModal.dataRequestModalHeading')}</h2>)
        }
        return gpcClassification
    }

    handleEmailChange = (prop) => (event) => {
        this.setState({email: event.target.value})
    };

    handleRequesterEmailChange = (prop) => (event) => {
        this.setState({requesterEmail: event.target.value})
    };

    handleFormChange = (i) => (event) => {
        const { name, value } = event.target;
        const itemDetails = [...this.state.itemDetails];

        if (event.target.tagName === 'LI') {
            itemDetails[i]['ekg'] =  event.target.innerText;
        } else {
            itemDetails[i][name] = value;
        }
        this.setState({itemDetails});
    };

    handleAddItem = () => {
        const item = {
            gln: '',
            tax_number: '',
            partner_name: '',
            gtin: '',
            description: '',
            article_type: '',
            ekg: ''
        };

        this.setState({
            itemDetails: [...this.state.itemDetails, item]
        });
    };

    handleRemoveSpecificRow = (i) => () => {
        const itemDetails = [...this.state.itemDetails]
        itemDetails.splice(i, 1)
        this.setState({ itemDetails })
    };

    validateForm(itemDetails, requesterEmail) {
        let gln = itemDetails.filter(item => item.gln === "")
        let gtin = itemDetails.filter(item => item.gtin === "")
        let ekg = itemDetails.filter(item => item.ekg === "")

        if (gln.length > 0 || gtin.length > 0 || ekg.length > 0 ||  requesterEmail === "") {
            return false
        }

        return true
    }

    exportMarkantData(event) {
        let fileName = ''
        let itemDetails = this.state.itemDetails
        let requesterEmail = this.state.requesterEmail
        let countryCode = this.state.value

        if(this.validateForm(itemDetails, requesterEmail)) {
            this.props.apiService.exportMarkantDataToExcel(itemDetails, requesterEmail, countryCode, this.props.language)
                .then(res => {
                    let contentDisposition = res.headers.get('content-disposition').split(';')[1]
                    fileName = contentDisposition.split('=')[1]
                    return res.blob();
                }).then(blob => {
                    if (blob !== undefined) {
                        if (blob instanceof Blob) {
                            saveAs(blob, fileName);
                        } else if ('responseDescription' in blob) {
                            alert(blob['responseDescription'])
                        }
                    }
                }).catch(err => console.error(err));
        } else {
            this.setState({tosterOpen: true})

            return false
        }
    }

    sendEmail(event) {
        let itemDetails = this.state.itemDetails
        let requesterEmail = this.state.requesterEmail
        let mailTo = this.state.email
        let countryCode = this.state.value

        if(this.validateForm(itemDetails, requesterEmail)) {
            this.setState({emailIconDisable: true})
            this.setState({emailIconColor: ''})

            this.props.apiService.sendEmail(itemDetails, requesterEmail, countryCode, mailTo, this.props.language)
                .then(res => {
                    return res.json()
                }).then(res => {
                    if (res["status_code"] === "200") {
                        this.setState({emailSuccessToster: true})
                    } else {
                        this.setState({emailErrorToster: true})
                    }

                    this.setState({emailIconDisable: false})
                    this.setState({emailIconColor: 'primary'})

                }).catch(err => console.error(err));
        } else {
            this.setState({tosterOpen: true})

            return false
        }
    }

    handleTosterClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({tosterOpen: false})
        this.setState({emailSuccessToster: false})
        this.setState({emailErrorToster: false})
    };
    
    calculateLabelWidth(text, addPadding) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = '14px Arial'
        const width = context.measureText(text).width + addPadding
        return width
    }

    render() {
        const { t } = this.props;
        return <Modal
            isOpen={this.props.toggleDataRequestModal}
            style={{ overlay: { zIndex: 1000 } }}
            ariaHideApp={false}
        >
            <Snackbar open={this.state.tosterOpen} autoHideDuration={6000} onClose={this.handleTosterClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={this.handleTosterClose} severity="error">
                    {t('markantDataRequestModal.allMandatoryFieldsRequired')}
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.emailSuccessToster} autoHideDuration={6000} onClose={this.handleTosterClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={this.handleTosterClose} severity="success">
                    {t('markantDataRequestModal.emailSentSuccessfully')}
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.emailErrorToster} autoHideDuration={6000} onClose={this.handleTosterClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={this.handleTosterClose} severity="error">
                    {t('markantDataRequestModal.errorSendingEmail')}
                </Alert>
            </Snackbar>
            <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', margionTop: '-15px' }}>
                <Tooltip title={t('markantDataRequestModal.sendEmail')} aria-label={t('markantDataRequestModal.sendEmail')}>
                    <IconButton disabled={this.state.emailIconDisable} onClick={this.sendEmail} >
                        <EmailIcon color={this.state.emailIconColor} style={{cursor: "pointer", marginRight: '8%'}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('markantDataRequestModal.download')+ " Excel"} aria-label={t('markantDataRequestModal.sendEmail')}>
                    <IconButton disabled={false} color="primary" onClick={this.exportMarkantData}>
                        <GetAppIcon color="primary" style={{cursor: "pointer", marginRight: '8%'}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('markantDataRequestModal.close')}> 
                    <IconButton
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.props.handleDataRequestModalClose}
                        style={{ outline: 'none',  marginRight: '8%'}}
                    >
                        <CloseRoundedIcon style={{ color: palette.warning.close }} />
                    </IconButton>
                </Tooltip>  
            </div>
            <div style={{paddingLeft: '16px'}}>
                <h2 key={'h1'}>Markant {t('markantDataRequestModal.dataRequestModalHeading')}</h2>
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={2} style={{paddingLeft: "15px"}}>
                            <Autocomplete
                                value={this.state.value}
                                onChange={(event, newValue) => {
                                    this.setState({value: newValue})
                                    this.setState({email: countryEmailIds[newValue]})
                                    this.setState({countryEkgCodeList: this.props.ekgCodeList[newValue]})
                                }}
                                inputValue={this.state.inputValue}
                                onInputChange={(event, newInputValue) => {
                                    this.setState({inputValue: newInputValue})
                                }}
                                id="controllable-states-demo"
                                options={emailCountries}
                                style={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label={t('markantDataRequestModal.countryCode')} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={3} style={{paddingLeft: "15px"}}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-amount">{t('markantDataRequestModal.mailTo')}</InputLabel>
                                <OutlinedInput
                                    readOnly
                                    id="outlined-adornment-amount"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange('email')}
                                    startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}
                                    labelWidth={this.calculateLabelWidth(t('markantDataRequestModal.mailTo'), 15)}
                                    style={{ width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} style={{paddingLeft: "15px"}}>
                            <FormControl fullWidth variant="outlined">
                            
                                <InputLabel htmlFor="outlined-adornment-amount">
                                    <div style={{color: 'red'}}>{t('markantDataRequestModal.requestorEmail')} *</div>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    value={this.state.requesterEmail}
                                    onChange={this.handleRequesterEmailChange('requesterEmail')}
                                    startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}
                                    labelWidth={this.calculateLabelWidth(t('markantDataRequestModal.requestorEmail'), 35)}
                                    style={{ width: '100%' }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div style={{marginTop: '32px'}}>
                    <div style={{color: 'red'}}>* {t('markantDataRequestModal.mandatoryField')}</div>
                    <div style={{marginTop: '6px'}}>GLN = Global Location Number</div>
                </div>
                <div style={{marginTop: '16px'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{fontWeight: 'bold', padding: '8px', color: 'red', 
                                                                            maxWidth: '100%',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',} }>{t('markantDataRequestModal.GLNBusinessPartner')}*</div>
                        </Grid>
                        <Grid item xs={1} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{ fontWeight: 'bold', padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>{t('markantDataRequestModal.taxNumber')}</div>
                        </Grid>
                        <Grid item xs={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{fontWeight: 'bold', padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('markantDataRequestModal.businessPartnerName')}</div>
                        </Grid>
                        <Grid item xs={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{fontWeight: 'bold', padding: '8px', color: 'red', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('markantDataRequestModal.GTINBasisProductPiece')}</div>
                        </Grid>
                        <Grid item xs={2} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{ fontWeight: 'bold', padding: '8px', maxWidth: '100%',overflow: 'hidden',textOverflow: 'ellipsis'}}>{t('markantDataRequestModal.productDescription')}</div>
                        </Grid>
                        <Grid item xs={1} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{ fontWeight: 'bold', padding: '8px', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('markantDataRequestModal.articleType')}</div>
                        </Grid>
                        <Grid item xs={1} style={{ backgroundColor: palette.text.veryLight, marginRight: '8px'}}>
                            <div key={'div'} style={{ fontWeight: 'bold', padding: '8px', color: 'red', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>EKG*</div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
                {this.state.itemDetails.map((value, i) => {
                    return <div style={{marginTop: '8px'}} key={i}>
                        <Grid container spacing={1} style={{display: 'flex', flexWrap: 'nowrap' }}>
                            <Grid item xs={2} style={{ marginRight: '8px'}}>
                                <div key={i}>
                                    <TextField name="gln" id="standard-required" fullWidth
                                        value={this.state.itemDetails[i].gln}
                                        onChange={this.handleFormChange(i)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={1} style={{ marginRight: '8px'}}>
                                <div key={i}>
                                    <TextField  name="tax_number" id="standard-required" fullWidth
                                        value={this.state.itemDetails[i].tax_number}
                                        onChange={this.handleFormChange(i)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={2} style={{ marginRight: '8px'}}>
                                <div key={i}>
                                    <TextField name="partner_name" id="standard-required" fullWidth
                                        value={this.state.itemDetails[i].partner_name}
                                        onChange={this.handleFormChange(i)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={2} style={{ marginRight: '8px'}}>
                                <div key={i}>
                                    <TextField name="gtin" id="standard-required" fullWidth
                                        value={this.state.itemDetails[i].gtin}
                                        onChange={this.handleFormChange(i)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={2} style={{ marginRight: '8px'}}>
                                <div key={i}>
                                    <TextField name="description" id="standard-required" fullWidth
                                        value={this.state.itemDetails[i].description}
                                        onChange={this.handleFormChange(i)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={1} style={{ marginRight: '8px'}}>
                                <div key={i}>
                                    <TextField name="article_type" id="standard-required" fullWidth
                                        value={this.state.itemDetails[i].article_type}
                                        onChange={this.handleFormChange(i)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={1} style={{ marginRight: '8px', marginTop: '3px'}}>
                                <div key={i}>
                                    <Autocomplete
                                        id="size-small-standard"
                                        size="small"
                                        onChange={this.handleFormChange(i)}
                                        // inputValue={this.state.itemDetails[i].ekg}
                                        options={this.state.countryEkgCodeList}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="standard" />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <div key={i} xs={1} style={{ paddingTop:'14px' }}>
                                <DeleteIcon
                                    style={{ color: red[500], cursor: 'pointer' }}
                                    onClick={this.handleRemoveSpecificRow(i)}
                                />
                            </div>
                        </Grid>
                    </div>
                })}
                <div style={{marginTop: '16px'}}>
                    <button onClick={this.handleAddItem} className="btn btn-sm">
                        {t('markantDataRequestModal.addItem')}
                    </button>
                </div>
            </div>
        </Modal>;
    }
}

export default withTranslation()(DataRequestModal);