import React, {useState} from 'react';
import { translateKey } from '../../common/helpers/Common';

const Expandable = ({ maxChars, children}) => {

    const prefix = 'productDetailsModal';
    const fullText = children
    const truncatedText = fullText.substr(0, maxChars)
    const [expanded, setExpanded] = useState(false)
    const toggleBtn = () => {
        setExpanded(!expanded)
    }

    return (
        <div>
           {expanded ? fullText : truncatedText}
            <a onClick={toggleBtn} style={{color: "blue", cursor: "pointer"}}>
            {" ... " + translateKey(expanded ? 'showLess' : 'showMore', prefix)}
            </a>
        </div>
        
    )
}

export default Expandable