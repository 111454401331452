
import { regionList } from '../../layouts/modals/components/RegionMapping';

export function mapRegion(region) {
    let regionName = ''
        if (region in regionList){
            regionName= regionList[region]
        }
    return regionName
}

export function mapLand(regionCode) {
    let countryName = ''
    let landCode = ''
    if (regionCode in regionList){
        landCode=regionCode.substring(0, 2)
        countryName= regionList[landCode]
    }
return countryName
}


