import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { CustomerContext } from "../../../common/CustomerContext.js";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { mapRegion} from '../../../common/helpers/RegionHelper'
import { mapLand} from '../../../common/helpers/RegionHelper';
import PubliclyPublishedProducts from "./PubliclyPublishedProducts.jsx";
import {palette} from '../../../assets/Palette';

class AddProducerPopup extends React.Component {
    static contextType = CustomerContext;


    constructor(props) {
        super(props);
        this.state = {
            isPubliclyPublishedProductsDisplayed: false,
            item: {},
            wineMakerlist: [],
            publicProducers: {},
        }

        this.linkOnClick = this.linkOnClick.bind(this);
        this.openPubliclyPublishedProductsComponent = this.openPubliclyPublishedProductsComponent.bind(this);
        this.getPublicPublishedProducts = this.getPublicPublishedProducts.bind(this);
        this.togglePubliclyPublishedProducts = this.togglePubliclyPublishedProducts.bind(this);

    }

    componentDidUpdate(){
        if(this.state.wineMakerlist !== this.props.wineMakerlist){   
            console.log("updating component")
            this.props.wineMakerlist.forEach( item => {
                this.getPublicPublishedProducts(item.winid)
            })
            this.setState({
                    wineMakerlist: this.props.wineMakerlist
            });
        }
    }

    linkOnClick(item) {

        console.log("submit button on click");

        this.context.apiService.getLinkProducer(item.winid)
            .then(res => {
                console.log("Euvino Response");
                console.log(res);
                if (res.ok) {
                this.props.refreshSearchProducers();
                this.props.refreshKnownProducers();
                } else {
                        console.log(res);
                        console.log(res.errors) }
            });
    }

    getPublicPublishedProducts(winId) {
        this.setState({ isLoadingComplete: false });
        this.context.apiService.getPublicPublishedProducts(winId)
            .then(res =>  {
                console.log("Euvino Response");
                console.log(res)
                if (res.ok) {
                    if(res.status === 204){
                        console.log("Euvino Response No content -", res.status)
                        this.setState({
                            publicProducers: {
                                ...this.state.publicProducers,
                                [winId]: []
                            }
                        });
                    } else {
                        res.json().then(res => {
                            if ( res.hasOwnProperty('status') && res.status == "too-many-results") {
                                res = res['preview']
                            }
                            this.setState({
                                publicProducers: {
                                    ...this.state.publicProducers,
                                    [winId]: res
                                }
                            });
                        });
                    }
                } 
            });
    }

    openPubliclyPublishedProductsComponent(item){
        console.log("try to open component, state:", this.state.publicProducers)
        this.setState({
            item: item,
            isPubliclyPublishedProductsDisplayed: true
        });
    }
    
    togglePubliclyPublishedProducts(){
        console.log("toggle PubliclyPubishedProducts")
        this.setState({
            isPubliclyPublishedProductsDisplayed: !this.state.isPubliclyPublishedProductsDisplayed
        })
    }

    render() {
        const { t } = this.props; 
        console.log("AddProducerPopup", this.props)
        return (
        <div >
        { this.props.isSuccess ?
                <div>
                    <Typography align="center">{t('euvinoModal.producerPopup')}</Typography>
                    <IconButton
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.closePopup}
                            style={{ outline: 'none' }}
                    ><CloseRoundedIcon style={{ color: palette.warning.close }} />
                    </IconButton>
                    {this.state.isPubliclyPublishedProductsDisplayed && 
                        <PubliclyPublishedProducts 
                            winId={this.state.item.winid} 
                            winmakerName={this.state.item.name} 
                            publicProducerList={this.state.publicProducers[this.state.item.winid]}
                            isPubliclyPublishedProductsDisplayed={this.state.isPubliclyPublishedProductsDisplayed}
                            closePubliclyPublishedProducts={this.togglePubliclyPublishedProducts} 
                    />}
                    <Paper elevation={2}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><b>{t('euvinoModal.producer')}</b></TableCell>
                                    <TableCell align="center"><b>WIN-ID</b></TableCell>
                                    <TableCell align="center"><b>{t('euvinoModal.region')}</b></TableCell>
                                    <TableCell align="center"><b>{t('euvinoModal.country')}</b></TableCell>
                                    <TableCell align="center"><b>{t('euvinoModal.action')}</b></TableCell>
                                    <TableCell align="center"><b>{t('euvinoModal.search')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                this.props.wineMakerlist.map(item => (
                                <TableRow key={item.winid}>
                                    <TableCell align="left">{item.name}</TableCell>
                                    <TableCell align="center">{item.winid }</TableCell>
                                    <TableCell align="center">{mapRegion(item.region)}</TableCell>
                                    <TableCell align="center">{mapLand(item.region)}</TableCell>
                                    <TableCell align="center">
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        disabled={item.linked}
                                        size="small"
                                        onClick={this.linkOnClick.bind(this,item)}
                                        >Link</Button>
                                    </TableCell>
                                    <TableCell align="center">
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        disabled={ this.state.publicProducers.hasOwnProperty(item.winid) ? !Object.keys(this.state.publicProducers[item.winid]).length > 0 : true }
                                        onClick={this.openPubliclyPublishedProductsComponent.bind(this, item)}
                                        >search</Button>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
            :null}
        </div>
        );
    }
}
export default withTranslation()(AddProducerPopup);
