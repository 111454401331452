import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React from "react";
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Form, Input } from "reactstrap";
import { palette } from '../assets/Palette';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: true
        }
        document.body.classList.add("white-content");
        this.handleClick = this.handleClick.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
        this.keyPressedSSO = this.keyPressedSSO.bind(this);
        localStorage.removeItem('user');
        localStorage.removeItem('saved');
    }

    componentDidMount() {
        let source = process.env.REACT_APP_SOURCE
        if (source === 'migros') {
            this.setState({
                url: process.env.REACT_APP_MIGROS_URL,
            })
        } else if (source === 'schwarz') {
            const mode = process.env.REACT_APP_MODE;
            switch (mode) {
                case "test":
                    this.setState({
                    url: process.env.REACT_APP_SCHWARZ_URL,
                    })
                    break;

                case "mvp":
                    this.setState({
                    url: process.env.REACT_APP_SCHWARZ_URL_MVP,
                    })
                    break;
                
                case "lidl":
                    this.setState({
                    url: process.env.REACT_APP_SCHWARZ_URL_LIDL,
                    })
                    break;

                case "prod":
                    this.setState({
                    url: process.env.REACT_APP_SCHWARZ_URL_PROD,
                    })
                    break;

                default:
                    break;
                }
        } else if (source === 'radeberger') {
            this.setState({
                url: process.env.REACT_APP_RADEBERGER_URL,
            })
        } else if (source === 'flaschenpost') {
            this.setState({
                url: process.env.REACT_APP_FLASCHENPOST_URL,
            })
        } else if (source === 'bc') {
            this.setState({
                url: process.env.REACT_APP_BC_CONTENT_AGGREGATION_URL,
            })
        } else if (source === 'denner') {
            this.setState({
                url: process.env.REACT_APP_MODE === 'prod'? process.env.REACT_APP_DENNER_CONTENT_AGGREGATION_URL_PROD : process.env.REACT_APP_DENNER_CONTENT_AGGREGATION_URL,
            })
        } else if (source === 'aldi') {
            this.setState({
                url: process.env.REACT_APP_MODE === 'prod'? process.env.REACT_APP_ALDI_CONTENT_AGGREGATION_URL_PROD : process.env.REACT_APP_ALDI_CONTENT_AGGREGATION_URL,
            })
        } else if (source === 'markant-book') {
            this.setState({
                url: process.env.REACT_APP_MARKANT_URL,
            })
        }
    }

    handleUsernameChange(e) {
        this.setState({
            username: e.target.value
        })
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    handleClick() {
        fetch(this.state.url + 'login/', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: this.state.username, password: this.state.password })
        }).then(res => {
            if (res.status === 200) {
                this.setState({
                    error: false
                })
                localStorage.setItem('user', this.state.username);
                localStorage.setItem('saved', new Date().getTime())
                this.props.history.push('/content-provider')
            }
            else if (res.status === 401) {
                this.setState({
                    error: true
                })
                alert('Falsche Anmeldedaten')
            }
        })
    }

    handleClickSSO() {
        fetch(this.state.url + 'saml/login/', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log('SSO Login response')
            console.log(res)
            if (res.status === 200) {
                this.setState({
                    error: false
                })
            }
            return res
        })
        .then(res => res.json())
        .then(res => {
            if('redirect' in res){
                window.location.href =res['redirect']
            }
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.handleClick()
        }
    }

    keyPressedSSO(event) {
        if (event.key === "Enter") {
            this.handleClickSSO()
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: '#000033' }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={3}>
                        <Card style={{ width: '50vh' }}>
                            <CardHeader>
                                <div style={{ position: 'relative', left: '50%', WebkitTransform: 'translateX(-50%)', transform: 'translateX(-50%)' }}>
                                    {process.env.REACT_APP_SOURCE === 'schwarz' ? <img src={require('../assets/img/schwarz_logo.png')} alt="logo" /> : <img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" width='192' height='53' />}
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Form>
                                        <Input
                                            onKeyPress={this.keyPressed}
                                            tabIndex='1'
                                            type='text'
                                            placeholder="Benutzername"
                                            style={ {borderColor: palette.secondary.main} }
                                            required
                                            onChange={this.handleUsernameChange}
                                        />
                                        <br />
                                        <Input
                                            onKeyPress={this.keyPressed}
                                            tabIndex='2'
                                            type="password"
                                            placeholder="Passwort"
                                            style={ {borderColor: palette.secondary.main} }
                                            onChange={this.handlePasswordChange}
                                        />
                                        <br />
                                        <Button variant="contained" startIcon={<LockOpenIcon />} tabIndex='3' onKeyPress={this.keyPressed} style={ process.env.REACT_APP_SOURCE === 'schwarz' ? { backgroundColor: "#3f51b5", color: "white", borderColor:'#042454',  position: 'relative', left: '50%', WebkitTransform: 'translateX(-50%)', transform: 'translateX(-50%)' } : {backgroundColor: "#2ad2c5", color: "white", borderColor:'red',  position: 'relative', left: '50%', WebkitTransform: 'translateX(-50%)', transform: 'translateX(-50%)' } } onClick={() => this.handleClick()}>Anmelden</Button>
                                        <br />
                                        {process.env.REACT_APP_SOURCE === 'schwarz' ?
                                        <Button variant="contained" startIcon={<LockOpenIcon />} tabIndex='4' onKeyPress={this.keyPressed} style={ {backgroundColor: "#3f51b5", color: "white", borderColor:'#042454',  position: 'relative', marginTop:'4%', left: '50%', WebkitTransform: 'translateX(-50%)', transform: 'translateX(-50%)' } } onClick={() => this.handleClickSSO()}>Anmelden SSO</Button>
                                        : null}
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withRouter(Login)


