
import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { isGtinValid, isGlnValid, isGpcValid, isTargetMarketSubscription, isSubscriptionCombinationCorrect } from '../../common/helpers/Validator'
import { CustomerContext } from "../../common/CustomerContext.js";
import MenuItem from '@material-ui/core/MenuItem';
import GdsnSubscription from "../../common/models/GdsnSubscription.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorList from "../modals/components/ErrorList.jsx";
import Alert from '@material-ui/lab/Alert';
import {getCountryNameWithCode} from '../../common/helpers/CountryCodeHelper';
import{getCurrentLanguage} from '../../common/helpers/Common';
import {palette} from '../../assets/Palette';

class CreateGdsnSubscription extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            subscriberGln: '',
            targetMarket: '',
            gln: '',
            gtin: '',
            gpc: '',

            glnError: '',

            validTargetMarkets: [],
            validSubscriberGlns: [],

            shouldHelperTextAppear: true,

            isSubmitting: false,
            isSubmitted: false,
            isSubmissionSuccessful: false,
            submissionErrors: []
        };
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleTargetMarket = this.handleTargetMarket.bind(this);
        this.handleSubscriberGln = this.handleSubscriberGln.bind(this);
        this.submitButtonOnClick = this.submitButtonOnClick.bind(this);
        this.closeHelperText = this.closeHelperText.bind(this);
    }

    componentDidMount() {
        this.getValidTargetMarkets();
        this.getValidSubscriberGlns();
    }

    getValidTargetMarkets() {
        this.context.apiService.getValidTargetMarketsForGdsnSubscriptions()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validTargetMarkets: res.data });
                    });
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }

    getValidSubscriberGlns() {
        this.context.apiService.getValidSubscriberGlns()
            .then(res => {
                if (res.ok) {
                    res.json().then(res => {
                        this.setState({ validSubscriberGlns: res.data });
                    })
                } else {
                    res.json().then(res => {
                        console.log(res);
                    })
                }
            });
    }

    handleOnChange(event) {
        let fieldId = event.target.id;
        let value = event.target.value;
        this.setState({
            [fieldId]: value
        });
    }

    handleTargetMarket(event) {
        this.setState({
            targetMarket: event.target.value
        })
    }

    handleSubscriberGln(event) {
        console.log(event);
        this.setState({
            subscriberGln: event.target.value
        })
    }

    shouldSubmitButtonBeEnabled() {
        return (isGtinValid(this.state.gtin)
            && isGlnValid(this.state.gln)
            && isGpcValid(this.state.gpc)
            && (this.state.targetMarket.length !== 0)
            && (this.state.subscriberGln.length !== 0)
            && isSubscriptionCombinationCorrect(this.state.targetMarket,
                this.state.gtin,
                this.state.gln,
                this.state.gpc)
            && !isTargetMarketSubscription(this.state.targetMarket,
                this.state.gtin,
                this.state.gln,
                this.state.gpc));
    }

    submitButtonOnClick() {
        console.log("submit button on click");
        this.setState({
            isSubmitting: true,
            isSubmitted: false,
        })
        let sub = new GdsnSubscription();
        sub.subscriberGln = this.state.subscriberGln;
        sub.targetMarket = this.state.targetMarket;
        sub.gtin = this.state.gtin;
        sub.gln = this.state.gln;
        sub.gpc = this.state.gpc;

        this.context.apiService.createGdsnSubscription(sub)
            .then(res => {
                console.log(res);
                if (res.ok) {
                    this.setState({
                        isSubmitting: false,
                        isSubmitted: true,
                        isSubmissionSuccessful: true,

                        subscriberGln: '',
                        targetMarket: '',
                        gln: '',
                        gtin: '',
                        gpc: ''
                    });

                    this.props.handleRefreshPage();
                } else {
                    res.json().then(res => {
                        console.log(res);
                        console.log(res.errors)
                        this.setState({
                            isSubmitting: false,
                            isSubmitted: true,
                            isSubmissionSuccessful: false,
                            submissionErrors: res.errors
                        });
                    });

                }
            });
    }

    closeHelperText() {
        this.setState({ shouldHelperTextAppear: false });
    }


    render() {
        const { t } = this.props;
        const language = getCurrentLanguage(process.env.REACT_APP_SOURCE)

        return <Grid container direction="row" justifyContent="space-evenly">
                <Grid item>
                    <TextField
                        label={t('gdsnSubscriptionModal.subscriberGln')}
                        id="subscriberGln"
                        value={this.state.subscriberGln}
                        onChange={this.handleSubscriberGln}
                        select variant="outlined" size="small"
                        style={{ width: '260px' }}
                        disabled={this.state.isSubmitting}
                    >
                        {this.state.validSubscriberGlns.map(gln => <MenuItem key={gln} value={gln}>{gln}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        label={t('gdsnSubscriptionModal.targetMarket')}
                        id="targetMarket"
                        value={this.state.targetMarket}
                        onChange={this.handleTargetMarket}
                        select variant="outlined" size="small"
                        style={{ width: '194px', borderColor: palette.secondary.main}}
                        disabled={this.state.isSubmitting}
                    >
                        {this.state.validTargetMarkets.map(tm => <MenuItem key={tm} value={tm}>{getCountryNameWithCode(tm, language)}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField id="gln"
                        label={t('gdsnSubscriptionModal.supplierGln')}
                        value={this.state.gln}
                        error={!isGlnValid(this.state.gln)}
                        helperText={isGlnValid(this.state.gln) ? '' : t('gdsnSubscriptionModal.glnValidationText')}
                        onChange={this.handleOnChange} variant="outlined" size="small"
                        inputProps={{ maxLength: 13 }}
                        disabled={this.state.isSubmitting}
                    />
                </Grid>
                <Grid item>
                    <TextField id="gtin"
                        label="GTIN"
                        value={this.state.gtin}
                        error={!isGtinValid(this.state.gtin)}
                        helperText={isGtinValid(this.state.gtin) ? '' : t('gdsnSubscriptionModal.gtinValidationText')}
                        onChange={this.handleOnChange} variant="outlined" size="small"
                        inputProps={{ maxLength: 14 }}
                        disabled={this.state.isSubmitting}
                    />
                </Grid>
                <Grid item>
                    <TextField id="gpc"
                        label="GPC"
                        value={this.state.gpc}
                        error={!isGpcValid(this.state.gpc)}
                        helperText={isGpcValid(this.state.gpc) ? '' : t('gdsnSubscriptionModal.gpcValidationText')}
                        onChange={this.handleOnChange} variant="outlined" size="small"
                        disabled={this.state.isSubmitting}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained"
                        color="primary"
                        disabled={!this.shouldSubmitButtonBeEnabled() || this.state.isSubmitting}
                        onClick={this.submitButtonOnClick}
                        style={{ height: '40px' }}
                    >{t('gdsnSubscriptionModal.submit')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <br />
                    {this.state.shouldHelperTextAppear &&
                        <Alert severity="info"
                            onClose={this.closeHelperText}
                        >
                            {t('gdsnSubscriptionModal.gdsnSubscriptionHelpText1')}
                            <br />{t('gdsnSubscriptionModal.gdsnSubscriptionHelpText2')}
                        </Alert>}
                </Grid>
                <Grid item xs={12}>
                    <div><br />
                        {this.state.isSubmitting && <div><br /><LinearProgress /></div>}
                        {this.state.isSubmitted ?
                            !this.state.isSubmissionSuccessful ?
                                <ErrorList errors={this.state.submissionErrors} />
                                : <Alert severity="success">{t('gdsnSubscriptionModal.newGdsnSubscriptionSuccessMessage')}</Alert>
                            : null
                        }
                    </div>
                </Grid>
            </Grid>

        ;
    }

}
export default withTranslation()(CreateGdsnSubscription);