export default class GdsnSubscription {
    id;
    subscriberGln;
    gln;
    targetMarket;
    gtin;
    gpc;
    state;
    isDeleted;
    createdAt;
    updatedAt;
}