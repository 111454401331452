
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from "react";
import Modal from 'react-modal';
import { Form, Input, Label } from "reactstrap";
import i18n from '../../i18n';
import Tooltip from '@material-ui/core/Tooltip';
import {palette} from '../../assets/Palette';

const passwordModalStyle = {
    content: {
        top: '40%',
        left: '50%',
        right: '75%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overlay: { zIndex: 1000 },
        height: '70%'
    }
};

class ChangePasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            oldPassword: '',
            newPassword1: '',
            newPassword2: ''
        }
        this.updatePassword = this.updatePassword.bind(this);
        this.detectEnterKey = this.detectEnterKey.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.setOldPassword = this.setOldPassword.bind(this);
        this.setNewPassword1 = this.setNewPassword1.bind(this);
        this.setNewPassword2 = this.setNewPassword2.bind(this);

    }

    componentDidMount() {
        let source = process.env.REACT_APP_SOURCE
        if (source === 'migros') {
            this.setState({
                url: process.env.REACT_APP_MIGROS_URL,
            })
        } else if (source === 'schwarz') {
            this.setState({
                url: process.env.REACT_APP_SCHWARZ_URL,
            })
        } else if (source === 'radeberger') {
            this.setState({
                url: process.env.REACT_APP_RADEBERGER_URL,
            })
        } else if (source === 'flaschenpost') {
            this.setState({
                url: process.env.REACT_APP_FLASCHENPOST_URL,
            })
        } else if (source === 'bc') {
            this.setState({
                url: process.env.REACT_APP_BC_CONTENT_AGGREGATION_URL,
            })
        } else if (source === 'denner') {
            this.setState({
                url: process.env.REACT_APP_MODE === 'prod'? process.env.REACT_APP_DENNER_CONTENT_AGGREGATION_URL_PROD : process.env.REACT_APP_DENNER_CONTENT_AGGREGATION_URL,
            })
        } else if (source === 'aldi') {
            this.setState({
                url: process.env.REACT_APP_MODE === 'prod'? process.env.REACT_APP_ALDI_CONTENT_AGGREGATION_URL_PROD : process.env.REACT_APP_ALDI_CONTENT_AGGREGATION_URL,
            })
        } else if (source === 'markant-book') {
            this.setState({
                url: process.env.REACT_APP_MARKANT_URL,
            })
        }
    }

    setUsername(e) {
        this.setState({
            username: e.target.value
        })
    }

    setOldPassword(e) {
        this.setState({
            oldPassword: e.target.value
        })
    }

    setNewPassword1(e) {
        this.setState({
            newPassword1: e.target.value
        })
    }

    setNewPassword2(e) {
        this.setState({
            newPassword2: e.target.value
        })
    }

    detectEnterKey(event) {
        if (event.key === "Enter") {
            this.updatePassword()
        }
    }

    isValidPassword(password) {
        if(password.length < 12) {
            return false
        } else {
            return /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W_])/.test(password)
        }
    }

    updatePassword() {
        if (this.state.newPassword1 === this.state.newPassword2) {
            if(this.isValidPassword(this.state.newPassword1)){
                fetch(this.state.url + 'update-password/', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username: this.state.username, password: this.state.oldPassword, newPassword: this.state.newPassword1 })
                }).then(res => {
                    if (res.status === 200) {
                        alert(i18n.t('changePasswordModal.changePasswordAlerts.success'))
                        this.props.toggleChangePassword()
                    }
                    else if (res.status === 401) {
                        alert(i18n.t('changePasswordModal.changePasswordAlerts.fail'))
                    }
                })
            } else {
                alert(i18n.t('changePasswordModal.changePasswordAlerts.requirements'))
            }
        } else {
            alert(i18n.t('changePasswordModal.changePasswordAlerts.mismatch'))
        }
    }

    render() {
        return <Modal
            isOpen={this.props.toggleChangePasswordModal}
            toggle={this.props.toggleChangePassword}
            style={passwordModalStyle}
            ariaHideApp={false}
        >
            <Tooltip title={i18n.t('changePasswordModal.close')}>
                <IconButton
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.props.toggleChangePassword}
                    style={{ outline: 'none' }}
                >
                    <CloseRoundedIcon style={{ color: palette.warning.close }} />
                </IconButton>
            </Tooltip>
            <h2>{i18n.t('changePasswordModal.changePassword')}</h2>
            <div style={{ marginTop: '1vh' }}>
                <Form>
                    <Label>{i18n.t('changePasswordModal.userName')}</Label>
                    <Input
                        type='text'
                        placeholder="Benutzername"
                        style={ process.env.REACT_APP_SOURCE === 'schwarz' ? {borderColor: "#66a3ff" } : {borderColor: palette.secondary.main } }
                        required
                        onChange={this.setUsername}
                        onKeyPress={this.detectEnterKey}
                    />
                    <br />
                    <Label>{i18n.t('changePasswordModal.oldPassword')}</Label>
                    <Input
                        type='password'
                        placeholder="Altes Passwort"
                        style={ process.env.REACT_APP_SOURCE === 'schwarz' ? {borderColor: "#66a3ff" } : {borderColor: palette.secondary.main } }
                        required
                        onChange={this.setOldPassword}
                        onKeyPress={this.detectEnterKey}
                    />
                    <br />
                    <Label>{i18n.t('changePasswordModal.newPassword')}</Label>
                    <Input
                        type="password"
                        placeholder="Neues Passwort"
                        required
                        style={ process.env.REACT_APP_SOURCE === 'schwarz' ? {borderColor: "#66a3ff" } : {borderColor: palette.secondary.main } }
                        onChange={this.setNewPassword1}
                        onKeyPress={this.detectEnterKey}
                    />
                    <br />
                    <Label>{i18n.t('changePasswordModal.repeatNewPassword')}</Label>
                    <Input
                        type="password"
                        placeholder="Neues Passwort wiederholen"
                        required
                        style={ process.env.REACT_APP_SOURCE === 'schwarz' ? {borderColor: "#66a3ff" } : {borderColor: palette.secondary.main } }
                        onChange={this.setNewPassword2}
                        onKeyPress={this.detectEnterKey}
                    />
                    <br />
                    <Button variant="contained" onKeyPress={this.detectEnterKey} color="primary" style={ process.env.REACT_APP_SOURCE === 'schwarz' ? {backgroundColor: "#66a3ff", position: 'relative', left: '50%', WebkitTransform: 'translateX(-50%)', transform: 'translateX(-50%)' } : {backgroundColor: palette.secondary.main, position: 'relative', left: '50%', WebkitTransform: 'translateX(-50%)', transform: 'translateX(-50%)' }} onClick={() => this.updatePassword()}>{i18n.t('changePasswordModal.changePassword')}</Button>
                </Form>
            </div>
        </Modal>;
    }
}


export default ChangePasswordModal;
