import React from "react";

export const CustomerContext = React.createContext(
    {
        customer: 'default',
        apiService: null,
        viewRenderer: null,
        isSubscriptionEnabled: false,
        isBasicAuthEnabled: false,
        isCicEnabled: false,
        isGdsnTradeItemEnabled: false,
        isExportEnabled: false,
        isChildGtinSwitchEnabled: false
    }
);
