import ViewRenderer from './base/ViewRenderer';
import React from "react";
import {palette} from '../../assets/Palette';

export default class SchwarzViewRenderer extends ViewRenderer {

  renderTable(records, generateOtherOriginsXMLContent, toggleGPCModalSearch) {
    let table = []
    if (records !== null) {
      for (let i = 0; i < records.length; i++) {
        let children = []
        var record = records[i]
        let productName = ''
        let productNameList = record['functionalName']
        let description = ''
        let descriptionList = record['descriptionShort']
        let brickDescriptionDe = ''
        let isProductNameLanguageFound = false
        let isDescriptionLanguageFound = false
        let langList = ['de','en','fr','it','sv']
        if (productNameList !== null && productNameList !== undefined) {
          for (const langCode of langList){
            if(!isProductNameLanguageFound){
              for (let i = 0; i < productNameList.length; i++){
                if (langCode === productNameList[i].lang){
                  productName = productNameList[i].value
                  isProductNameLanguageFound = true
                  break;
                }
              }
            }
          }
          if (!isProductNameLanguageFound) {
            productName = productNameList[0].value
          }
        }
        if (descriptionList !== null && descriptionList !== undefined) {
          for (const langCode of langList){
            if(!isDescriptionLanguageFound){
              for (let i = 0; i < descriptionList.length; i++){
                if (langCode === descriptionList[i].lang){
                  description = descriptionList[i].value
                  isDescriptionLanguageFound = true
                  break;
                }
              }
            }
          }
          if (!isDescriptionLanguageFound) {
            description = descriptionList[0].value
          }
        }
        if ('gpcBrickDescriptionDe' in record) {
          brickDescriptionDe = record['gpcBrickDescriptionDe']
        }
        let updatedDate = new Date(record["updatedAt"]).toLocaleString()
        if ('thumbnail' in record) {
          children.push(<td key={i + ',1'} onClick={() =>  generateOtherOriginsXMLContent(i)} align='center'><div style={{ margin: 'auto', width: '75px', height: '75px' }}><img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={'data:image/png;base64,' + record['thumbnail']} /></div></td>)
        } else {
          children.push(<td key={i + ',1'} onClick={() =>  generateOtherOriginsXMLContent(i)}></td>)
        }
        children.push(<td key={i + ',2'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["gtin"]}</td>)
        children.push(<td key={i + ',3'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["brandName"]}</td>)
        children.push(<td key={i + ',4'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{description}</td>)
        children.push(<td key={i + ',5'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{productName}</td>)
        children.push(<td key={i + ',6'} onClick={() => toggleGPCModalSearch(i)}><div style={{ color: palette.primary.main }}>{record["gpcCategoryCode"] + ' - ' + brickDescriptionDe}</div></td>)
        if (record["Manufacturer"] !== null) {
          if (record["Manufacturer"].partyName === '' && 'informationProviderOfTradeItem' in record) {
            children.push(<td key={i + ',7'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["informationProviderOfTradeItem"]}</td>)
          } else {
            children.push(<td key={i + ',7'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["Manufacturer"].partyName}</td>)
          }
        } else {
          children.push(<td key={i + ',7'} onClick={() =>  generateOtherOriginsXMLContent(i)}></td>)
        }
        children.push(<td key={i + ',8'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["tradeItemUnitDescriptorCode"]}</td>)
        children.push(<td key={i + ',9'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["countryCodeAlpha2"] + ' (' + record["targetMarket"] + ')'}</td>)
        children.push(<td key={i + ',10'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{updatedDate}</td>)
        children.push(<td key={i + ',11'} onClick={() =>  generateOtherOriginsXMLContent(i)}>{record["source"]}</td>)
        table.push(<tr key={i} style={{ cursor: 'pointer' }}>{children}</tr>)
      }
    }
    return table
  }
}