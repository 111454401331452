import React from "react";
import { withRouter } from 'react-router-dom';
// reactstrap components
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import {palette} from '../assets/Palette'

class Help extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        document.body.classList.add("white-content");
    }

    generateDocumentation() {
        let doc = []
        let source = process.env.REACT_APP_SOURCE
        let mode = process.env.REACT_APP_MODE
        
        doc.push(<h3 key='h3'>Hilfe zur Such-API</h3>)
        switch (source){
            case 'schwarz':
                const doc_url = `https://schwarz-stackit-contentaggregation-${mode}.bayard-consulting.com/schwarz-docs/`
                doc.push(<a key='doc' href={doc_url} target='_blank' rel="noopener noreferrer">Technische Dokumentation Such-API</a>)
                break;
            case 'radeberger':
                doc.push(<a key='doc' href='http://radeberger-contentaggregation-test.bayard-consulting.com/radeberger-docs/' target='_blank' rel="noopener noreferrer">Technische Dokumentation Such-API</a>)
                break;
            case 'aldi':
                doc.push(<a key='doc' href='http://aldi-contentaggregation-test.bayard-consulting.com/aldi-docs/' target='_blank' rel="noopener noreferrer">Technische Dokumentation Such-API</a>)
                break;
            case 'denner':
                doc.push(<a key='doc' href='http://denner-contentaggregation-test.bayard-consulting.com/denner-docs/' target='_blank' rel="noopener noreferrer">Technische Dokumentation Such-API</a>)
                break;
            case 'bc':
                doc.push(<a key='doc' href='http://productcontent-test.bayard-consulting.com/aldi-docs/' target='_blank' rel="noopener noreferrer">Technische Dokumentation Such-API</a>)
                break;
            default:
                break;
        }
        return doc;
    }


    render() {
        return (
            <>
                <div className="content">
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.6%' }}>
                                    <img src={require('../assets/img/BYRD_ContentHub_horizontal_color_RGB.png')} alt="logo" width='192' height='53' />
                                </div>
                            </CardHeader>
                            <CardBody style={{ marginTop: '2%' }}>
                                <div style={{ fontSize: '1rem', color: palette.text.main  }}>
                                    <h2 style={{ color: palette.text.dark }}>Hilfe zur Suche</h2>
                                    <h3 style={{ color: palette.text.dark }}>Allgemeine Informationen</h3>
                                    <p style={{ color: palette.text.main }}>Die Suche wird durch Eingabe eines einzelnen Suchbegriffs oder mehrerer, durch Leerzeichen getrennter Begriffe im Suchfeld angestoßen.<br />
                                    Die Suche berücksichtigt dabei alle im Suchindex enthaltenen Attribute der verfügbaren Artikel. D.h. es kann vorkommen, das Artikel in der Trefferliste aufgelistet werden, der Suchbegriff dort aber nicht direkt ersichtlich ist.<br /><br /></p>
                                    <h4 style={{ color: palette.text.main }}>Einstellungen</h4>
                                    <p style={{ color: palette.text.main }}>Für die Suche können verschiedene Einstellungen gemacht werden:<br /><br /></p>
                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>Artikel pro Seite</b><br />Gibt an, wie viele Einträge in der Suchtrefferliste angezeigt werden. Je mehr Einträge gewählt werden, umso mehr zeit wird ggfs. benötigt, um das Ergebnis anzuzeigen.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Logische Verknüpfung</b><br /><b>Und - </b>die eingegebenen Suchbegriffe müssen ALLE in einem Artikel gefunden werden.<br />Die Eingabe der Suchbegriffe “Fisch Fleisch” mit dieser Option findet nur Artikel, die den Begriff “Fisch” UND den Begriff “Fleisch” in beschreibenden Attributen enthalten.<br /><b>Oder - </b>mindestens einer der Suchbegriffe muss einem Artikel gefunden werden.<br />Die Eingabe der Suchbegriffe “Fisch Fleisch” mit dieser Option findet Artikel, die entweder den Begriff “Fisch” ODER den Begriff “Fleisch” ODER beide Begriffe in beschreibenden Attributen enthalten.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Suche nach exaktem Begriff</b><br />Ist diese Option aktiv, werden die Suchbegriffe nur in der exakten Schreibweise gefunden, ansonsten werden auch abweichende Schreibweisen gefunden.<br />Beispiel: Die Eingabe des Suchbegriffs “Jogurt” findet ohne diese aktive Option auch Artikel mit der Schreibweise “Joghurt” oder “Yoghurt”.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Suchen in</b><br />Einschränkung der zu durchsuchenden Datenmenge auf einen bestimmten angebundenen Datenlieferanten. Aktuell nur GDSN.</li><br />
                                        <li style={{ color: palette.text.main }}><b>Suchumfang</b><br />Die Datenmenge beinhaltet nicht nur Konsumentenartikel sondern auch Verpackungen, wie z.B. Kartons oder Paletten. Mit der aktiven Option “Nur Konsumentenartikel berücksichtigen” werden die Verpackungen aus dem Suchergebnis ausgeschlossen.</li>
                                    </ul><br />
                                    <h3 style={{ color: palette.text.main }}>Nutzung von Attributbezeichnern als Suchfilter</h3>
                                    <p style={{ color: palette.text.main }}>
                                    Mit Hilfe von Attributbezeichnern des Suchindex können Suchen gezielt auf bestimmte Attribute eingeschränkt werden. Der Attributbezeichner wird als Präfix mit Deppelpunkt getrennt dem Suchbegriff vorangestellt. Auf die exakte Schreibweise (Groß-/Kleinschreibung) des Attributbezeichners ist zu achten.<br /><br />
                                    Beispiel: brandName:Suchbegriff beschränkt die Suche von „Suchbegriff“ auf das Attribut „Markenname“, z.B. “brandName:Fanta” findet nur Artikel die den Begriff “Fanta” im Markennamen enthalten.<br /><br />
                                    <b style={{ color: palette.text.main }}>Liste der möglichen Suchfilter:</b><br /><br /></p>
                                    <ul style={{ borderLeft: '0px' }}>
                                        <li style={{ color: palette.text.main }}><b>source:</b> (Datenquelle, im Moment nur “GDSN” möglich)</li>
                                        <li style={{ color: palette.text.main }}><b>productName:</b> (Artikelbezeichnung)</li>
                                        <li style={{ color: palette.text.main }}><b>productDescription:</b> (Artikelbeschreibung)</li>
                                        <li style={{ color: palette.text.main }}><b>brandName:</b> (Markenname)</li>
                                        <li style={{ color: palette.text.main }}><b>manufacturer:</b> (Hersteller/Lieferant)</li>
                                        <li style={{ color: palette.text.main }}><b>targetMarket:</b> (Zielmarkt)</li>
                                        <li style={{ color: palette.text.main }}><b>gtin:</b> (GTIN eines Artikels)</li>
                                        <li style={{ color: palette.text.main }}><b>gln:</b> (GLN eines Herstellers)</li>
                                        <li style={{ color: palette.text.main }}><b>gpc:</b> (GPC Code Baustein, Klassem Familie oder Segment)</li>
                                    </ul><br />
                                    {this.generateDocumentation()}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </>
        );
    }
}
export default withRouter(Help)
