import APIService from './base/APIService.js';

export default class SchwarzAPIService extends APIService {

    constructor() {
        super()
        this.MODE = process.env.REACT_APP_MODE
        switch (this.MODE) {
            case "prod":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL_PROD
                break;

            case "test":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL
                break

            case "mvp":
                this.API_PATH = process.env.REACT_APP_SCHWARZ_URL_MVP
                break

            default:
                break;
        }
    }
    DEFAULT_TARGET_MARKET = '276'

    // @TODO Remove below function after decommissioning the Schwarz platform
    getProductDetails(xml, origin) {
        return this.postWithUIToken('get-product-details/', JSON.stringify({ xmlContent: xml, origin: origin }));
    }

    getProductDetailsFromEs(productId, origin, contentLanguage, labelLanguage) {
        return this.postWithUIToken('get-product-details-from-es/', JSON.stringify({ productId: productId, origin: origin, 
            contentLanguage: contentLanguage, labelLanguage: labelLanguage }));
    }

    getGPCData(gpcCategoryCode) {
        return this.postWithUIToken('get-gpc-data/', JSON.stringify({ gpcCode: gpcCategoryCode }));
    }

    getTargetMarkets() {
        let query = {
            aggs: {
                targetMarkets: {
                    terms: {
                        field: "targetMarket",
                        size: 10000
                    }
                }
            }
        }
        return this.postWithUIToken('get-target-markets/', JSON.stringify(query));
    }

    getDataCount() {
        return this.getWithUIToken('get-sources/');
    }

    getDashboardData() {
        return this.getWithUIToken('get-dashboard-data/');
    }

    exportData(gtinList, tm_list) {
        return this.postWithUIToken('export-data/', JSON.stringify({ gtinList: gtinList, tm_list}));
    }

    search(keyword, size, offset, logicalOperator, originSwitches, descriptorCode, isConsumerUnit, exactSearch, targetMarketSwitches) {
        let keywords = keyword.split(' ')
        let negationKeywords = []
        let filterAttributesList = []
        let searchQuery = ''
        let targetMarketQuery = ''
        let originQuery = ''
        for (let i = 0; i < keywords.length; i++) {
            if (keywords[i][0] === '-') {
                if (keywords[i].length > 1) {
                    negationKeywords.push(keywords[i])
                }
            }
            else {
                if (keywords[i].length > 0) {
                    if (keywords[i].includes(':')) {
                        if (keywords[i].split(':')[1].length > 0) {
                            let filterAttributePair = keywords[i].split(':')
                            let filterCondition = {}
                            let attribute = filterAttributePair[1].replace('-', ' * ')
                            filterCondition = this.mapSchwarzFilterAttributes(filterAttributePair[0], attribute, exactSearch)
                            filterAttributesList.push(filterCondition)
                            if (exactSearch) {
                                searchQuery = searchQuery + ' (' + filterAttributePair[1] + ') ' + logicalOperator
                            } else {
                                searchQuery = searchQuery + ' (*' + filterAttributePair[1] + '*) ' + logicalOperator
                            }
                        }
                    }
                    else {
                        if (exactSearch) {
                            searchQuery = searchQuery + ' (' + keywords[i] + ') ' + logicalOperator
                        } else {
                            searchQuery = searchQuery + ' (*' + keywords[i] + '*) ' + logicalOperator
                        }
                    }
                }
            }
        }
        if (logicalOperator === 'AND') {
            searchQuery = searchQuery.slice(0, -4)
        } else if (logicalOperator === 'OR') {
            searchQuery = searchQuery.slice(0, -3)
        }
        for (let i = 0; i < negationKeywords.length; i++) {
            searchQuery = searchQuery + ' ' + negationKeywords[i]
        }
        for (let i = 0; i < targetMarketSwitches.length; i++) {
            for (let key in targetMarketSwitches[i]) {
                if (targetMarketSwitches[i][key] === true) {
                    targetMarketQuery = targetMarketQuery + ' OR ' + key
                }
            }
        }
        for (let i = 0; i < originSwitches.length; i++) {
            for (let key in originSwitches[i]) {
                if (originSwitches[i][key] === true) {
                    originQuery = originQuery + ' OR ' + key
                }
            }
        }
        originQuery = originQuery.substring(4)
        targetMarketQuery = targetMarketQuery.substring(4)
        let isConsumerUnitOnly_query = {
            'query_string': {
                 'query': isConsumerUnit,
                 'fields': ['isTradeItemAConsumerUnit']
            }
        }
        let query = {
            query: {
                bool: {
                    must: [
                        {
                            bool: {
                                should: [
                                    {
                                        query_string: {
                                            query: searchQuery,
                                            fields: ['gtin', 'source', 'brandName', 'targetMarket', 'Manufacturer.partyName', 'Manufacturer.gln', 'gpcCategoryCode', 'gpcBrickDescriptionDe', 'gpcClassCode', 'gpcClassDescriptionDe', 'gpcFamilyCode', 'gpcFamilyDescriptionDe', 'gpcSegmentCode', 'gpcSegmentDescriptionDe', 'tradeItemUnitDescriptorCode']
                                        }
                                    },
                                    {
                                        nested: {
                                            path: "functionalName",
                                            query: {
                                                query_string: {
                                                    query: searchQuery,
                                                    fields: ['functionalName.value']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        nested: {
                                            path: "descriptionShort",
                                            query: {
                                                query_string: {
                                                    query: searchQuery,
                                                    fields: ['descriptionShort.value']
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            'query_string': {
                                'query': descriptorCode,
                                fields: ['tradeItemUnitDescriptorCode']
                            }
                        },
                        {
                            'query_string': {
                                'query': targetMarketQuery,
                                fields: ['targetMarket']
                            }
                        },
                        {
                            'query_string': {
                                'query': originQuery,
                                fields: ['source']
                            }
                        },
                    ]
                }
            },
            'from': offset,
            'size': size
        }
        if(isConsumerUnit){
            query['query']['bool']['must'].push(isConsumerUnitOnly_query)
        }
        if (filterAttributesList.length > 0) {
            let condition = ''
            if (logicalOperator === 'AND') {
                condition = 'must'
            } else if (logicalOperator === 'OR') {
                condition = 'should'
            }
            let filterObject = {}
            filterObject['bool'] = {}
            filterObject['bool'][condition] = []
            for (let i = 0; i < filterAttributesList.length; i++) {
                filterObject['bool'][condition].push(filterAttributesList[i])
            }
            query['query']['bool']['must'].push(filterObject)
        }
        return this.post('_ui_search', JSON.stringify(query));
    }

    mapSchwarzFilterAttributes(filter, attribute, exactSearch) {
        let filterCondition = {}
        if (filter === 'productDescription') {
            filterCondition['nested'] = {}
            filterCondition['nested']['path'] = 'descriptionShort'
            filterCondition['nested']['query'] = {}
            filterCondition['nested']['query']['query_string'] = {}
            if (exactSearch) {
                filterCondition['nested']['query']['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['nested']['query']['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['nested']['query']['query_string']['fields'] = ['descriptionShort.value']
        }
        else if (filter === 'productName') {
            filterCondition['nested'] = {}
            filterCondition['nested']['path'] = 'functionalName'
            filterCondition['nested']['query'] = {}
            filterCondition['nested']['query']['query_string'] = {}
            if (exactSearch) {
                filterCondition['nested']['query']['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['nested']['query']['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['nested']['query']['query_string']['fields'] = ['functionalName.value']
        }
        else if (filter === 'manufacturer') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['Manufacturer.partyName']
        }
        else if (filter === 'gln') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['Manufacturer.gln']
        }
        else if (filter === 'gpc') {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = ['gpcCategoryCode', 'gpcBrickDescriptionDe', 'gpcClassCode', 'gpcClassDescriptionDe', 'gpcFamilyCode', 'gpcFamilyDescriptionDe', 'gpcSegmentCode', 'gpcSegmentDescriptionDe']
        }
        else {
            filterCondition['query_string'] = {}
            if (exactSearch) {
                filterCondition['query_string']['query'] = '(' + attribute + ')'
            } else {
                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
            }
            filterCondition['query_string']['fields'] = [filter]
        }
        return filterCondition
    }

    handleGPCManufacturerFilterButtons(theFilterList, originalRecordList, completeGPCData, language) 
    {
        const theManufacturers = theFilterList['manufacturer']
        const theGPCList = theFilterList['gpc']

        let recordList = originalRecordList.filter(function(product) {
            const gpcCode = product['gpcCategoryCode'] + ' - ' + product['gpcBrickDescriptionDe']

            if( 'Manufacturer' in product && 'partyName' in product['Manufacturer'] ) {
                return theManufacturers.includes(product['Manufacturer'].partyName) && theGPCList.includes(gpcCode)
            } else if('informationProviderOfTradeItem' in product) {
                return theManufacturers.includes(product['informationProviderOfTradeItem']) && theGPCList.includes(gpcCode)
            }
            return true
        })
        return { records: recordList };
    }
}