import { of } from 'rxjs';

export default class APIService {
    API_PATH = '';
    DEFAULT_TARGET_MARKET = '';

            
    getGdsnSubscriptions(useFilters, gln, gtin, targetMarket, gpc, pageNumber, perPage,
        subscriberGln = null, isDeleted = null, from = null, to = null) {
        let path = 'get-subscriptions/?';
        path += (pageNumber) ? "pageNumber=" + pageNumber : '';
        path += (perPage) ? "&perPage=" + perPage : '';
        if(useFilters)
        {
            path += (subscriberGln) ? "&subscriberGln=" + subscriberGln : '';
            path += (isDeleted) ? "&isDeleted=" + isDeleted : '';
            path += (gln) ? "&gln=" + gln : '';
            path += (gtin) ? "&gtin=" + gtin : '';
            path += (targetMarket) ? "&targetMarket=" + targetMarket : '';
            path += (gpc) ? "&gpc=" + gpc : '';
            path += (from) ? "&from=" + from.toISOString() : '';
            path += (to) ? "&to=" + to.toISOString() : '';
        }
        return this.getWithUIToken(path);
    }

    getGdsnSubscriptionActivities(useFilters, gln, gtin, targetMarket, gpc,pageNumber, perPage){
        let path = 'get-subscription-activities/?';
        path += (pageNumber) ? "pageNumber=" + pageNumber : '';
        path += (perPage) ? "&perPage=" + perPage : '';
        if(useFilters)
        {
            path += (gln) ? "&gln=" + gln : '';
            path += (gtin) ? "&gtin=" + gtin : '';
            path += (targetMarket) ? "&targetMarket=" + targetMarket : '';
            path += (gpc) ? "&gpc=" + gpc : '';
        }
        return this.getWithUIToken(path);
    }

    /**
     * @deprecated please implement
     * @TODO Remove below function after decommissioning the Schwarz And Migros platform
     */
    getProductDetails() {
        return this.mockResponse();
    }

    getProductDetailsFromEs() {
        return this.mockResponse();
    }

    /**
     * @deprecated please implement
     */
    getGPCData() {
        return this.mockResponse();
    }

    /**
     * @deprecated please implement
     */
    getTargetMarkets() {
        return this.mockResponse();
    }

    /**
     * @deprecated please implement
     */
    getDataCount() {
        return this.mockResponse();
    }

    /**
     * @deprecated please implement
     */
    getDashboardData() {
        return this.mockResponse();
    }

    /**
     * @deprecated please implement
     */
     getUserRights(username) {
        return this.getWithUIToken('get-user-rights/' + username);
    }

    getWithUIToken(path) {
        return fetch(this.API_PATH + path, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (response.status === 401) {
                console.error('Error:', response);
                localStorage.removeItem('user');
                localStorage.removeItem('saved');
                window.location.href = '/login';
                return
            }
            return response
          })
    }

    postWithUIToken(path, body) {
        return fetch(this.API_PATH + path, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }).then((response) => {
            if (response.status === 401) {
                console.error('Error:', response);
                localStorage.removeItem('user');
                localStorage.removeItem('saved');
                window.location.href = '/login';
                return
            }
            return response
          })
    }

    deleteWithUIToken(path, body) {
        return fetch(this.API_PATH + path, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: body
        });
    }

    /**
     * Wrapper for GET request
     * @param {*} path
     */
    get(path) {
        return fetch(this.API_PATH + path, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }

    /**
     * Wrapper for PATCH request
     * @param {*} path
     * @param {*} body
     */
    patch(path, body) {
        return fetch(this.API_PATH + path, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });
    }

    /**
     * Wrapper for POST request
     * @param {*} path
     * @param {*} body
     */
    post(path, body) {
        return fetch(this.API_PATH + path, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        }).then((response) => {
            if (response.status === 401) {
                console.error('Error:', response);
                localStorage.removeItem('user');
                localStorage.removeItem('saved');
                window.location.href = '/login';
                return
            }
            return response
          });
    }

    /**
     * Wrapper for DELETE request
     * @param {*} path
     * @param {*} body
     */
    delete(path, body) {
        return fetch(this.API_PATH + path, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });
    }

    mockResponse() {
        return of(new Response('{}', { status: 200 })).toPromise();
    }

    defaultEmptyDataResponse() {
        let body = '{"data": [], "total": 0}';
        return new Response(body, { status: 200 });
    }

    defaultUnimplementedErrorResponse() {
        let body = '{"errors": ["The feature is not implemented."]}';
        return new Response(body,{status: 501});
    }

    redirect_to_login() {

    }
}