
const palette = {
    primary: {
        main: '#002857',
        light: '#4777af',
        dark: '#022244',
    },
    secondary: {
        main: '#2ad2c5',
        light: '#93ebe4',
        dark: '#21a399',
    },
    info: {
        main: '#f7be00',
        light: '#f8d972',
        dark: '#f8ab06',
    },
    warning: {
        main: '#2ad265',
        light: '#93ebe4',
        dark: '#21a399',
        close: '#990000'
    },
    error: {
        main: '#ff595a',
        light: '#fa9c9c',
        dark: '#ff4545',
    },
    success: {
        main: '#00953f',
        light: '#7ad09e',
        dark: '#12793d',
    },
    text: {
        main: '#4f5659',
        dark: '#34393b',
        light: '#cac9ca',
        veryLight: '#f0f0f1',
        ui_text: '#222A42B3'
    },
    grey: {
        byrd1: '#939598',
        byrd2: '#cac9ca'
    }
}

export {palette}