/* eslint-disable no-useless-escape */
export const regionList = {
  "DE":"Deutschland",
  "DE1":"Ahr",
  "DE2":"Baden",
  "DE3":"Franken",
  "DE4":"Hessische Bergstraße",
  "DE5":"Mittelrhein",
  "DE6":"Mosel",
  "DE7":"Nahe",
  "DE8":"Pfalz",
  "DE9":"Rheingau",
  "DE10":"Rheinhessen",
  "DE11":"Saale-Unstrut",
  "DE12":"Sachsen",
  "DE13":"Württemberg",
  "FR":"Frankreich, ",
  "FR1":"Alsace",
  "FR2":"Beaujolais",
  "FR3":"Bordeaux",
  "FR3-1":"Barsac",
  "FR3-2":"Entre Deux Mers",
  "FR3-3":"Rive gauche (Linkes Ufer)",
  "FR3-4":"Rive droite (Rechtes Ufer)",
  "FR3-5":"Sauternes",
  "FR4":"Bourgogne",
  "FR4-1":"Chablis",
  "FR4-2":"Côte Chalonnaise",
  "FR4-3":"Beaune",
  "FR4-4":"Côte de Nuits",
  "FR4-5":"Mâconnais",
  "FR5":"Champagne",
  "FR6":"Corse",
  "FR7":"Jura",
  "FR8":"Languedoc-Roussillon",
  "FR8-1":"Languedoc",
  "FR8-2":"Roussillon",
  "FR9":"Provence",
  "FR9-1":"Méditerrannée",
  "FR10":"Savoie",
  "FR11":"Sud-Ouest",
  "FR11-1":"Bergerac",
  "FR12":"Vallée de la Loire",
  "FR12-1":"Pouilly Fumé",
  "FR12-2":"Sancerre",
  "FR12-3":"Anjou-Saumur",
  "FR12-4":"Touraine",
  "FR12-4-1":"Vouvray",
  "FR13":"Vallée du Rhône",
  "FR13-1":"Rhône septentrionale",
  "FR13-2":"Rhône méridionale",
  "FR14":"Bretagne",
  "FR15":"Nouvelle-Aquitaine",
  "FR15-1":"Charente",
  "FR15-1-1":"Cognac",
  "FR16":"Normandie",
  "FR17":"France d'outre-mer",
  "FR17-1":"Martinique",
  "FR17-2":"La Réunion",
  "CH":"Schweiz",
  "CH1":"Kanton Wallis",
  "CH2":"Kanton Waadt",
  "CH3":"Kanton Genf",
  "CH4":"Drei-Seen-Land",
  "CH5":"Kanton Tessin",
  "CH6":"Kanton Zürich",
  "CH7":"Kanton Schaffhausen",
  "CH8":"Kanton Aargau",
  "CH9":"Kanton Luzern",
  "CH10":"Thunersee",
  "CH12":"Kanton Graubünden",
  "CH12-1":"Bündner Herrschaft",
  "AT":"Österreich",
  "AT20":"Weinbauregion Weinland",
  "AT20-1":"Niederösterreich",
  "AT20-1-1":"Wachau",
  "AT20-1-2":"Kremstal",
  "AT20-1-3":"Kamptal",
  "AT20-1-4":"Traisental",
  "AT20-1-5":"Wagram",
  "AT20-1-6":"Weinviertel",
  "AT20-1-7":"Carnuntum",
  "AT20-1-8":"Thermenregion",
  "AT20-2":"Burgenland",
  "AT20-2-1":"Neusiedler See",
  "AT20-2-2":"Neusiedlersee-Hügelland",
  "AT20-2-3":"Mittelburgenland",
  "AT20-2-4":"Südburgenland",
  "AT20-2-5":"Leithaberg",
  "AT20-2-6":"Eisenberg",
  "AT21":"Weinbauregion Wien",
  "AT22":"Weinbauregion Steirerland",
  "AT22-1":"Südoststeiermark",
  "AT22-1-1":"Vulkanland",
  "AT22-2":"Südsteiermark",
  "AT22-3":"Weststeiermark",
  "AT23":"Weinbauregion Bergland",
  "AT23-1":"Kärnten",
  "AT23-2":"Oberösterreich",
  "AT23-3":"Salzburg",
  "AT23-4":"Tirol",
  "AT23-5":"Vorarlberg",
  "ES":"Spanien",
  "ES1":"Andalucia",
  "ES1-1":"Málaga",
  "ES1-1-1":"Ronda",
  "ES1-2":"Jerez-Xérès-Sherry",
  "ES2":"Aragon",
  "ES2-1":"Calatayud",
  "ES2-2":"Campo de Borja",
  "ES2-3":"Cariñena",
  "ES2-4":"Somontano",
  "ES3":"Illes Balears",
  "ES3-1":"Mallorca",
  "ES3-2":"Ibiza",
  "ES4":"Castilla-La Mancha",
  "ES4-1":"Dominio de Valdepusa",
  "ES4-2":"Manchuela",
  "ES4-3":"Toledo",
  "ES4-4":"Ribera del Júcar",
  "ES5":"Castilla y León",
  "ES5-1":"Bierzo",
  "ES5-2":"Cigales",
  "ES5-3":"Ribera del Duero",
  "ES5-4":"Rueda",
  "ES5-5":"Toro",
  "ES5-6":"Tierra del Vino de Zamora",
  "ES6":"Cataluña",
  "ES6-1":"Cava",
  "ES6-2":"Tarragona",
  "ES6-2-1":"Conca de Barberà",
  "ES6-3":"Montsant",
  "ES6-4":"Penedès",
  "ES6-5":"Priorat",
  "ES6-6":"Tella Alta",
  "ES6-7":"Costers del Segre",
  "ES6-8":"Pla de Bages",
  "ES7":"Comunidad Valenciana",
  "ES7-1":"Utiel-Requena",
  "ES8":"Extremadura",
  "ES9":"Galicia",
  "ES9-1":"Rías Baixas",
  "ES9-2":"Valdeorras",
  "ES9-3":"Ribeiro",
  "ES10":"La Rioja",
  "ES10-1":"Rioja Baja",
  "ES11":"Madrid",
  "ES12":"Navarra",
  "ES13":"País Vasco",
  "ES13-1":"Rioja Alavesa",
  "ES14":"Región de Murcia",
  "ES14-1":"Yecla",
  "ES14-2":"Jumilla",
  "ES15":"Principado de Asturias",
  "ES16":"Canarias",
  "ES17":"Cantabria",
  "IT":"Italien",
  "IT1":"Abruzzo",
  "IT2":"Basilicata",
  "IT3":"Calabria",
  "IT4":"Campania",
  "IT5":"Emilia-Romagna",
  "IT6":"Friuli-Venezia Giulia",
  "IT6-1":"Friuli",
  "IT6-2":"Venezia Giulia",
  "IT7":"Lazio",
  "IT8":"Liguria",
  "IT9":"Lombardia",
  "IT10":"Marche",
  "IT11":"Molise",
  "IT12":"Piemonte",
  "IT12-1":"Barbaresco",
  "IT12-2":"Barolo",
  "IT12-3":"Cortese di Gavi",
  "IT13":"Puglia",
  "IT14":"Sardegna",
  "IT15":"Sicilia",
  "IT16":"Toscana",
  "IT16-1":"Bolgheri",
  "IT16-2":"Carmignano",
  "IT16-3":"Chianti",
  "IT16-4":"Maremma",
  "IT16-5":"Montalcino",
  "IT16-6":"Montepulciano",
  "IT16-7":"San Gimignano",
  "IT16-8":"Scansano",
  "IT17":"Trentino-Alto Adige",
  "IT17-1":"Südtirol",
  "IT18":"Umbria",
  "IT19":"Valle d'Aosta",
  "IT20":"Veneto",
  "IT20-1":"Verona",
  "IT20-1-1":"Bardolino",
  "IT20-1-2":"Soave",
  "IT20-2":"Valpolicella",
  "IT21":"Lugana",
  "IT22":"Prosecco",
  "PT":"Portugal",
  "PT1":"Alentejo",
  "PT2":"Dão",
  "PT3":"Douro",
  "PT4":"Madeira",
  "PT5":"Vinho Verde",
  "PT6":"Bairrada",
  "PT7":"Estremadura",
  "PT8":"Terras do Sado",
  "PT8-1":"Palmela",
  "PT8-2":"Setúbal",
  "PT9":"Tejo",
  "PT9-1":"Ribatejo",
  "PT9-2":"Lisboa e Vale do Tejo",
  "PT10":"Açores",
  "CL":"Chile",
  "CL1":"Atacama",
  "CL1-1":"Valle de Copiapó",
  "CL1-2":"Valle del Huasco",
  "CL2":"Coquimbo",
  "CL2-1":"Valle del Elqui",
  "CL2-2":"Valle de Limarí",
  "CL2-3":"Valle de Choapa",
  "CL3":"Aconcagua",
  "CL3-1":"Valle del Aconcagua",
  "CL3-2":"Valle del Casablanca",
  "CL3-3":"Valle de San Antonio",
  "CL3-3-1":"Valle de Leyda",
  "CL3-4":"Valle del Marga-Marga",
  "CL4":"Valle Central",
  "CL4-1":"Valle del Maipo",
  "CL4-1-1":"San Jose",
  "CL4-2":"Valle de Rapel",
  "CL4-2-1":"Valle del Cachapoal",
  "CL4-2-2":"Valle de Colchagua",
  "CL4-3":"Valle de Curicó",
  "CL4-3-1":"Valle del Teno",
  "CL4-3-2":"Valle del Lontué",
  "CL4-4":"Valle del Maule",
  "CL4-4-1":"Valle del Claro",
  "CL4-4-2":"Valle del Loncomilla",
  "CL4-4-3":"Valle del Tutuvén",
  "CL5":"Valle Sur",
  "CL5-1":"Valle del Itata",
  "CL5-2":"Valle del Biobío",
  "CL5-3":"Valle del Malleco",
  "CL6":"Austral",
  "CL6-1":"Valle del Cautín",
  "CL6-2":"Valle de Osorno",
  "AR":"Argentinien",
  "AR1":"Región del Nuevo Cuyo",
  "AR1-1":"Mendoza",
  "AR1-2":"San Juan",
  "AR1-3":"San Luis",
  "AR1-4":"La Rioja",
  "AR2":"Región Norte Grande Argentino",
  "AR2-1":"Catamarca",
  "AR2-2":"Jujuy",
  "AR2-3":"Salta",
  "AR2-4":"Santiago del Estero",
  "AR2-5":"Tucumán",
  "AR2-6":"Misiones",
  "AR3":"Región Patagónica Argentina",
  "AR3-1":"Chubut",
  "AR3-2":"La Pampa",
  "AR3-3":"Neuquén",
  "AR3-4":"Río Negro",
  "AR3-5":"Santa Cruz",
  "AR3-6":"Tierra del Fuego",
  "AR4":"Región Centro",
  "AR4-1":"Córdoba",
  "AR4-2":"Entre Ríos",
  "AR5":"Buenos Aires",
  "AU":"Australien",
  "AU1":"New South Wales",
  "AU1-1":"Big Rivers",
  "AU1-2":"Central Ranges",
  "AU1-2-1":"Orange",
  "AU1-3":"Hunter Valley",
  "AU1-4":"Northern Rivers",
  "AU1-5":"Northern Slopes",
  "AU1-6":"South Coast",
  "AU1-7":"Southern New South Wales",
  "AU2":"Queensland",
  "AU2-1":"Granite Belt",
  "AU2-2":"South Burnett",
  "AU3":"South Australia",
  "AU3-1":"Barossa",
  "AU3-2":"Far North",
  "AU3-3":"Fleurieu",
  "AU3-3-1":"McLaren Vale",
  "AU3-4":"Limestone Coast",
  "AU3-4-1":"Coonawarra",
  "AU3-5":"Lower Murray",
  "AU3-6":"Mount Lofty Ranges",
  "AU3-6-1":"Adelaide Hills",
  "AU3-7":"The Peninsulas",
  "AU4":"Tasmania",
  "AU4-1":"Coal River",
  "AU4-2":"Derwent Valley",
  "AU4-3":"East Coast",
  "AU4-4":"North West",
  "AU4-5":"Pipers River",
  "AU4-6":"Southern",
  "AU4-7":"Tamar Valley",
  "AU5":"Victoria",
  "AU5-1":"Central Victoria",
  "AU5-2":"Gippsland",
  "AU5-3":"North East Victoria",
  "AU5-4":"North West Victoria",
  "AU5-5":"Port Phillip",
  "AU5-6":"Western Victoria",
  "AU6":"Western Australia",
  "AU6-1":"Greater Perth",
  "AU6-2":"South Western Australia",
  "AU6-3":"Manjimup",
  "AU6-4":"Margaret River",
  "AU6-5":"Pemberton",
  "AU6-6":"Coastal Region",
  "AU7":"South Eastern Australia",
  "ZA":"Südafrika",
  "ZA10":"Western Cape",
  "ZA10-1":"Breede River Valley",
  "ZA10-1-1":"Breedekloof",
  "ZA10-1-2":"Robertson",
  "ZA10-1-3":"Worcester",
  "ZA10-2":"Cape South Coast",
  "ZA10-2-1":"Cape Agulhas",
  "ZA10-2-1-1":"Elim",
  "ZA10-2-2":"Overberg",
  "ZA10-2-2-1":"Elgin",
  "ZA10-2-3":"Plettenberg Bay",
  "ZA10-2-4":"Swellendam",
  "ZA10-2-5":"Walker Bay",
  "ZA10-3":"Klein Karoo",
  "ZA10-3-1":"Calitzdorp",
  "ZA10-3-2":"Langeberg-Garcia",
  "ZA10-4":"Coastal Region",
  "ZA10-4-1":"Cape Peninsula",
  "ZA10-4-2":"Darling",
  "ZA10-4-3":"Franschhoek Valley",
  "ZA10-4-4":"Paarl",
  "ZA10-4-5":"Stellenbosch",
  "ZA10-4-6":"Swartland",
  "ZA10-4-7":"Tulbagh",
  "ZA10-4-8":"Tygerberg",
  "ZA10-4-9":"Wellington",
  "ZA10-4-10":"Constantia",
  "ZA10-5":"Olifants River",
  "ZA10-5-1":"Citrusdal Mountain",
  "ZA10-5-2":"Citrusdal Valley",
  "ZA10-5-3":"Lutzville Valley",
  "ZA10-6":"Cederberg",
  "ZA11":"Northern Cape",
  "ZA11-1":"Douglas",
  "ZA11-2":"Sutherland-Karoo",
  "ZA12":"Eastern Cape",
  "NZ":"Neuseeland",
  "NZ1":"Northland",
  "NZ2":"Auckland",
  "NZ2-1":"Waiheke Island",
  "NZ3":"Waikato\/Bay of Plenty",
  "NZ4":"Gisborne",
  "NZ5":"Hawke's Bay",
  "NZ6":"Wellington\/Wairarapa",
  "NZ7":"Marlborough",
  "NZ8":"Nelson",
  "NZ9":"Canterbury\/Waipara Valley",
  "NZ10":"Waitaki River Basin",
  "NZ12":"South Island",
  "NZ12-1":"Central Otago",
  "NZ13":"East Coast",
  "TR":"Türkei",
  "TR1":"Marmara\/Thrakien",
  "TR2":"Ägäis",
  "TR3":"Mittelmeer",
  "TR4":"Mittelnordanatolien",
  "TR5":"Mittelsüdanatolien",
  "TR6":"Mittelostanatolien",
  "TR6-1":"Elâzığ",
  "TR7":"Südostanatolien",
  "TR8":"Ostanatolien",
  "TR9":"Schwarzes Meer",
  "TR10":"Zentralanatolien",
  "TR10-1":"Kapadokya",
  "US":"USA",
  "US1":"Alabama",
  "US2":"Alaska",
  "US3":"Arizona",
  "US4":"Arkansas",
  "US5":"Kalifornien",
  "US5-1":"Central Coast AVA",
  "US5-1-1":"Monterey AVA",
  "US5-2":"North Coast AVA",
  "US5-2-1":"Napa Valley AVA",
  "US5-2-1-1":"Stags Leap District AVA",
  "US5-2-1-2":"Mt. Veeder AVA",
  "US5-2-1-3":"Oakville AVA",
  "US5-2-1-4":"Sonoma Valley AVA",
  "US5-2-1-5":"Alexander Valley AVA",
  "US5-2-1-6":"Diamond Mountain District AVA",
  "US5-2-1-7":"Howell Mountain AVA",
  "US5-2-2":"Mendocino AVA",
  "US5-3":"South Coast AVA",
  "US5-4":"Santa Cruz Mountains AVA",
  "US5-5":"Central Valley",
  "US5-6":"Klamath Mountains",
  "US5-7":"Sierra Foothills AVA",
  "US6":"Colorado",
  "US7":"Connecticut",
  "US8":"Delaware",
  "US9":"Florida",
  "US10":"Georgia",
  "US11":"Hawaii",
  "US12":"Idaho",
  "US13":"Illinois",
  "US14":"Indiana",
  "US15":"Iowa",
  "US16":"Kansas",
  "US17":"Kentucky",
  "US18":"Louisiana",
  "US19":"Maine",
  "US20":"Maryland",
  "US21":"Massachusetts",
  "US22":"Michigan",
  "US23":"Minnesota",
  "US24":"Mississippi",
  "US25":"Missouri",
  "US26":"Montana",
  "US27":"Nebraska",
  "US28":"Nevada",
  "US29":"New Hampshire",
  "US30":"New Jersey",
  "US31":"New Mexico",
  "US32":"New York",
  "US33":"North Carolina",
  "US34":"North Dakota",
  "US35":"Ohio",
  "US36":"Oklahoma",
  "US37":"Oregon",
  "US38":"Pennsylvania",
  "US39":"Rhode Island",
  "US40":"South Carolina",
  "US41":"South Dakota",
  "US42":"Tennessee",
  "US43":"Texas",
  "US44":"Utah",
  "US45":"Vermont",
  "US46":"Virginia",
  "US47":"Washington",
  "US47-1":"Columbia Valley AVA",
  "US47-1-1":"Horse Heaven Hills AVA",
  "US48":"West Virginia",
  "US49":"Wisconsin",
  "US50":"Wyoming",
  "CA":"Kanada",
  "CA1":"Ontario",
  "CA1-1":"Lake Erie North Shore",
  "CA1-2":"Niagara Peninsula",
  "CA1-3":"Prince Edward County",
  "CA1-4":"South Georgian Bay",
  "CA1-5":"Pelee Island",
  "CA2":"British Columbia",
  "CA2-1":"Okanagan Valley",
  "CA2-1-1":"Black Sage Road",
  "CA2-1-2":"Kelowna",
  "CA2-1-3":"Lake Country",
  "CA2-1-4":"West Kelowna\/Westbank",
  "CA2-1-5":"Mt Boucherie",
  "CA2-1-6":"Naramata Bench",
  "CA2-1-7":"Oliver",
  "CA2-1-8":"Osoyoos Lake",
  "CA2-1-9":"Okanagan Falls",
  "CA2-1-10":"Skaha Lake",
  "CA2-1-11":"Summerland\/Peachland",
  "CA2-1-12":"Golden Mile Bench",
  "CA2-2":"Similkameen Valley",
  "CA2-3":"Gulf Islands",
  "CA2-4":"Vancouver Island",
  "CA2-5":"Fraser Valley",
  "CA2-6":"Kootenays",
  "CA2-7":"Shuswap",
  "CA2-8":"Thompson River Valley",
  "CA2-9":"Thompson Valley",
  "CA2-10":"Lillooet",
  "CA3":"Quebec",
  "CA3-1":"Centre-du-Québec (Québec City Area)",
  "CA3-2":"Cantons-de-l'est (Eastern Townships)",
  "CA3-3":"Montérégie",
  "CA3-4":"Basses Laurentides (Lower Laurentials)",
  "CA3-5":"Lanaudière",
  "CA3-6":"Laurentials",
  "CA4":"Nova Scotia",
  "CA4-1":"Annapolis Valley",
  "CA4-2":"LaHave River Valley",
  "CA4-3":"Malagash Peninsula",
  "CA4-4":"Bear River Valley",
  "IL":"Israel",
  "IL1":"Galiläa",
  "IL1-1":"Golan-Höhen",
  "IL1-2":"Oberes Galiläa",
  "IL1-3":"Unteres Galiläa",
  "IL2":"Hügel von Judäa",
  "IL3":"Samson",
  "IL4":"Negev",
  "IL5":"Shomron",
  "LU":"Luxemburg",
  "LU1":"Luxemburgische Mosel",
  "HU":"Ungarn",
  "HU1":"Balaton",
  "HU1-1":"Badacsony",
  "HU1-2":"Balatonboglár",
  "HU1-3":"Balaton-felvidék",
  "HU1-4":"Balatonfüred-Csopak",
  "HU1-5":"Nagy-Somló",
  "HU1-6":"Zala",
  "HU2":"Duna",
  "HU2-1":"Csongrád",
  "HU2-2":"Hajós-Baja",
  "HU2-3":"Kunság",
  "HU3":"Eger",
  "HU3-1":"Bükk",
  "HU3-2":"Mátra",
  "HU4":"Észak-Dunántúl",
  "HU4-1":"Neszmély",
  "HU4-2":"Etyek-Buda",
  "HU4-3":"Mór",
  "HU4-4":"Pannonhalma",
  "HU5":"Pannon",
  "HU5-1":"Pécs",
  "HU5-2":"Szekszárd",
  "HU5-3":"Tolna",
  "HU5-4":"Villány-Siklós",
  "HU5-4-1":"Villány",
  "HU5-4-2":"Siklós",
  "HU6":"Sopron",
  "HU7":"Tokaj",
  "BG":"Bulgarien",
  "BG1":"Dunavska Ravnina\/Donauebene",
  "BG1-1":"Svishtov",
  "BG1-2":"Veliki Preslav",
  "BG2":"Trakijska Nizina\/Thrakiatal",
  "BG2-1":"Pazardjik",
  "BG2-2":"Sliven",
  "BG2-3":"Nowa Sagora",
  "BG3":"Schwarzmeerregion",
  "BG4":"Rosental",
  "BG5":"Struma-Tal",
  "BR":"Brasilien",
  "BR1":"Centro-Oeste\/Mittelwesten",
  "BR2":"Nordeste\/Nordosten",
  "BR2-1":"Rio São Francisco",
  "BR3":"Norte\/Norden",
  "BR4":"Sudeste\/Südosten",
  "BR5":"Sul",
  "BR5-1":"Rio Grande do Sul",
  "BR5-1-1":"Vale dos Vinhedos",
  "BR5-1-2":"Serra do Sudeste",
  "RO":"Rumänien",
  "RO1":"Transilvania",
  "RO2":"Moldova",
  "RO3":"Muntenia și Oltenia\/Walachei",
  "RO3-1":"Muntenia\/Große Walachei",
  "RO3-2":"Oltenia\/Kleine Walachei",
  "RO4":"Banat",
  "RO4-1":"Viile Timişului",
  "RO5":"Crisana și Maramures\/Kreischgebiet",
  "RO6":"Dobrogea\/Dobrudscha",
  "RO7":"Terasele Dunării\/Donauterrassen",
  "RO8":"Regiunea viticola a nisipurilor",
  "SI":"Slowenien",
  "SI1":"Podravje\/Drautal",
  "SI1-1":"Ljutomersko-Ormoske gorice",
  "SI2":"Posavje\/Save-Tal",
  "SI3":"Primorska\/Adria-Küstenland",
  "GR":"Griechenland",
  "GR1":"Thrakien",
  "GR2":"Makedonien",
  "GR2-1":"Chalkidiki",
  "GR2-2":"Florina",
  "GR2-2-1":"Amyndeo",
  "GR2-3":"Trilofos",
  "GR2-4":"Pangeo",
  "GR3":"Epirus",
  "GR4":"Thessalien",
  "GR4-1":"Rapsani",
  "GR5":"Mittelgriechenland",
  "GR6":"Attika",
  "GR7":"Ionische Inseln",
  "GR8":"Peloponnes",
  "GR9":"Südliche Ägäis",
  "GR9-1":"Santorin",
  "GR10":"Nördliche Ägäis",
  "GR11":"Kreta",
  "CN":"China",
  // "CN1":"Shandong",
  "CN1":"Hebei",
  "CN2-1":"Zhangjiakou",
  "CN2-2":"Qinhuangdao",
  "CN3":"Beijing",
  "CN4":"Xinjiang",
  "CN5":"Tonghua",
  "CN6":"Ningxia",
  "LB":"Libanon",
  "LB1":"Bekaa-Tal",
  "LB2":"Batroun",
  "LB3":"Jezzine",
  "LB4":"Chouf",
  "LB5":"Matn",
  "MA":"Marocco",
  "MA1":"The East",
  "MA1-1":"Angad",
  "MA1-2":"Berkane",
  "MA1-3":"Beni Sadden",
  "MA2":"Fès-Meknès",
  "MA2-1":"Guerrouane",
  "MA2-2":"Beni M’Tir",
  "MA2-3":"Saïss",
  "MA2-4":"Zerboune",
  "MA2-5":"Coteaux de l’Atlas",
  "MA3":"The Northern Plain",
  "MA3-1":"Gharb",
  "MA4":"Rabat-Casablanca",
  "MA4-1":"Zenatta",
  "MA4-2":"Chellah",
  "MA4-3":"Zemmour",
  "MA4-4":"Zaër",
  "MA5":"El-jadida",
  "MA6":"Doukkala",
  "MD":"Moldawien",
  "MD1":"Cahul (Southern zone)",
  "MD2":"Purcari (Southeastern zone)",
  "MD3":"Codru (Central zone)",
  "MD4":"Bălți (Nothern zone)",
  "UY":"Uruguay",
  "UY1":"Canelones",
  "UY1-1":"Atlantida",
  "UY2":"Montevideo",
  "UY3":"Maldonado",
  "UY4":"Rocha",
  "UY5":"San José",
  "UY6":"Colonia",
  "UY6-1":"Carmelo",
  "UY7":"Durazno",
  "UY8":"Rivera",
  "UY9":"Paysandu",
  "UY10":"Salto",
  "UY11":"Artigas",
  "UY12":"Florida",
  "UY13":"Lavalleja",
  "UY14":"Soriano",
  "UY15":"Tacuarembó",
  "UK":"Vereinigtes Königreich\/Großbritannien",
  "UK1":"East Anglia",
  "UK2":"Mercia",
  "UK3":"Southwest and Wales",
  "UK4":"Thames and Chiltern",
  "UK5":"Wessex",
  "UK5-1":"Hampshire",
  "UK6":"South East",
  "UK6-1":"Surrey",
  "UK6-2":"Sussex",
  "UK6-3":"Kent",
  "UK7":"Schottland"
  }
