const countryCode = {
  "276": "DE", // Deutschland,DE,DEU,276
  "616": "PL", // Poland,PL,POL,616
  "203": "CZ", // Czechia,CZ,CZE,203
  "100": "BG", // Bulgaria,BG,BGR,100
  "703": "SK", // Slovakia,SK,SVK,703
  "642": "RO", // Romania,RO,ROU,642
  "498": "MD", // Moldova (the Republic of),MD,MDA,498
  "191": "HR", // Croatia,HR,HRV,191
}

const countryCodeEmail = {
  "276": "pim.support@de.markant.com", // Deutschland,DE,DEU,276
  "616": "pim.support@pl.markant.com", // Poland,PL,POL,616
  "203": "pim.support@cz.markant.com", // Czechia,CZ,CZE,203
  "100": "pim.support@bg.markant.com", // Bulgaria,BG,BGR,100
  "703": "pim.support@sk.markant.com", // Slovakia,SK,SVK,703
  "642": "pim.support@ro.markant.com", // Romania,RO,ROU,642
  "498": "pim.support@md.markant.com", // Moldova (the Republic of),MD,MDA,498
  "191": "pim.support@hr.markant.com", // Croatia,HR,HRV,191
}

export function sendEmail(itemDetails, language, t) {
  let itemDescriptionShort = '', gtin = '', gln = '', targetMarketCountryCode = '', emailBody = '', emailSub = ''
   
  const targetMarket = findByKey(itemDetails, 'targetMarket')

  if (targetMarket) {
    targetMarketCountryCode = targetMarket["targetMarketCountryCode"]
    if (!targetMarketCountryCode) { 
      targetMarketCountryCode = targetMarket["MarketCountryCode"]
    }
  }

  const recipient = countryCodeEmail[targetMarketCountryCode]

  const resultKey = findByKey(itemDetails, 'descriptionShort')
  if (resultKey) {
    itemDescriptionShort = resultKey[0]["__value__"]
  }

  if (itemDetails.hasOwnProperty('gtin')) {
    gtin = itemDetails['gtin']
  }
      
  if (itemDetails.hasOwnProperty('informationProviderOfTradeItem') ) {
    gln = itemDetails["informationProviderOfTradeItem"]["gln"]
  }
  else if (itemDetails.hasOwnProperty('gln')) { 
    gln = itemDetails["gln"]
  }
  
  emailBody = `${t('productDetailsModal.country')}: ${countryCode[targetMarketCountryCode]}%0D%0AGLN: ${gln}%0D%0AGTIN: ${gtin}%0D%0A`;
  emailBody+= `${t('productDetailsModal.articleDescription')}: ${itemDescriptionShort}%0D%0A%0D%0A${t('productDetailsModal.errorDescription')}: ${t('productDetailsModal.errorDescriptionText')}`;
  emailSub = t('productDetailsModal.emailSubject') + ' Kaufland' 

  window.open('mailto:'+recipient+'?subject='+emailSub+'&body='+emailBody) 
  
  return null;
}
  
export function findByKey(obj, targetKey) {
  for (let key in obj) {
    if (key === targetKey) {
      return obj[key];
    } else if (typeof obj[key] === 'object') {
      const result = findByKey(obj[key], targetKey);
      if (result) {
        return result;
      }
    }
  }
  return null;
}