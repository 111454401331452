
import React from "react";
import Modal from 'react-modal';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomerContext } from "../../common/CustomerContext.js";
import { withTranslation } from 'react-i18next';
import SearchWinemaker from "./components/SearchWinemaker.jsx";
import InviteWinemaker from "./components/InviteWinemaker.jsx";
import KnownProducers from "./components/KnownProducers.jsx";
import Tooltip from '@material-ui/core/Tooltip';
import {palette} from '../../assets/Palette';


class EuvinoSubscriptionModal extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            refreshKnownProducersFlag: false,
        };

    }


    updateKnownProducers() {   this.setState({ refreshKnownProducersFlag: true });}

    render() {

        return <Modal
            isOpen={this.props.isEuvinoSubscriptionModalDisplayed}
            toggle={this.props.toggleEuvinoSubscriptionModal}
            ariaHideApp={false}
        >   
            <Tooltip title={this.props.t('euvinoModal.close')}>
                <IconButton
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.props.toggleEuvinoSubscriptionModal}
                    style={{ outline: 'none' }}
                >
                    <CloseRoundedIcon style={{ color: palette.warning.close }} />
                </IconButton>
            </Tooltip>
            <h2>Euvino Subscription</h2>
            <SearchWinemaker  refreshKnownProducers={()=>this.updateKnownProducers()} />
            <InviteWinemaker/>
            <KnownProducers updateKnownProducersFlag={this.state.refreshKnownProducersFlag} />
            <hr />

        </Modal>
    }
}

export default withTranslation()(EuvinoSubscriptionModal);
