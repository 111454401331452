
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from "react";
import Modal from 'react-modal';
import { Card, CardBody } from "reactstrap";
import i18n from '../../i18n';
import Tooltip from '@material-ui/core/Tooltip';
import {palette} from '../../assets/Palette';

class ReleaseModal extends React.Component {

    generateReleaseData() {
        let nestedCard = []
        let div = []
        nestedCard.push(<Card><CardBody style={{ backgroundColor: '#f2f2f2', marginLeft: '1.5%' }}><div>{this.generateChangelog()}</div></CardBody></Card>)
        div.push(<h2 key='releaseh2'>1.0.0 Release Notes</h2>)
        div.push(<h4 key='releaseh4'>Release 1.0.0, September 7th 2020</h4>)
        div.push(<ul key='releaseul' style={{ borderLeft: '0px' }}><li key='releaseli' style={{ color: '#525f7f' }}><b>Improvements</b></li></ul>)
        div.push(nestedCard)
        return div
    }


    generateChangelog() {
        let changelog = []
        let div = []
        let description = [
            'Implement new table-style product detail view.',
            'Improve start page with statistics/overview information.',
            'Remove column "XML INHALT" by making the hit list entry clickable.',
            'Improve GPC category search by making the entries clickable.',
            'Implement hit list filter based on GPC and manufacturer column.',
            'Add target markets to presets.',
            'Image preview in grid style.'
        ]
        for (let i = 0; i < description.length; i++) {
            changelog.push(description[i])
            changelog.push(<br key={'br1' + description[i]} />)
            changelog.push(<br key={'br2' + description[i]} />)
        }
        div.push(changelog)
        return div
    }

    render() {
        return <Modal
            isOpen={this.props.releaseModal}
            toggle={this.props.toggleReleaseModal}
            style={{ overlay: { zIndex: 1000 } }}
            ariaHideApp={false}
        >
            <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '6%' }}>
                <Tooltip title={i18n.t('dashboard.close')}>
                    <IconButton
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.props.toggleReleaseModal}
                        style={{ outline: 'none' }}
                    >
                        <CloseRoundedIcon style={{ color: palette.warning.close }} />
                    </IconButton>
                </Tooltip>
            </div>
            <div>{this.generateReleaseData()}</div>
        </Modal>;
    }
}

export default ReleaseModal;